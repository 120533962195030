/************
## Helpers ##
************/

// Clearfix
// -------------------------

@mixin cf() {
    *zoom: 1;
    &:before, &:after { content: " "; display: table; }
    &:after { clear: both; }
}


@mixin panel(){
	background-color: #fff;
	padding: 20px;
	display: inline-block;
}

@mixin font-small(){
	font-size: 18px;
	@media (min-width: 700px) {
		font-size: 22px;
	}
}

@mixin font-medium(){
	font-size: 22px;
	@media (min-width: 700px) {
		font-size: 26px;
	}
	@media (min-width: 1000px) {
		// font-size: 30px;
	}
}


@mixin font-big(){
	font-size: 24px;
	@media (min-width: 700px) {
		font-size: 32px;
	}
}

@mixin visually-hidden(){
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute;
}
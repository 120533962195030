/**********
## Fonts ##
**********/

$font-size-base: 16px;
$body-font-family: 'A';
// $body-font-family: 'Source Sans Pro';

/************
## Spacing ##
************/

$spacing-small: 5px;
$spacing-medium: 10px;
$spacing-large: 20px;
$spacing-xlarge: 40px;
$spacing-xxlarge: 80px;


// $body-color: #49a51b;
$body-color: black;
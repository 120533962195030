.header{
	background: url(../assets/images/mt-bg.jpg);
	background-size: cover;
	background-position: right 22% center;
	@media (min-width: 700px) {
		background-position: center center;
	}
    //padding-top: 40px;
    padding-bottom: 40px;
    @media (min-width: 500px) {
    	//padding-top: 60px;
    	padding-bottom: 60px;
    }

    @media (min-width: 700px) {
    	//padding-top: 80px;
    	padding-bottom: 80px;
    }
    @media (min-width: 900px) {
    	//padding-top: 120px;
    	// padding-bottom: 80px;
    	padding: 0;
    	height: 80vh;

    }
}

.page-intro-container{
	// @media (min-width: 500px) {
	// 	margin-left: 20px;
	// }
	// @media (min-width: 700px) {
	// 	margin-left: 40px;
	// }
	text-align: center;
}	
.page-intro{
	margin-bottom: 10px;
	font-size: 14px;
	@media (min-width: 700px) {
		font-size: 16px;
	}
	// background: black;
	color: #000;
	display: inline-block;
	padding: 5px 0;
	//border: 1px solid black;
	// border-bottom: 1px solid black;
	// border-top: 1px solid black;
	margin-top: 20px;
	margin-bottom: 20px;
	// font-style: italic;
	// text-transform: uppercase;
	// > span{
	// 	@include visually-hidden();
	// }
	.logo{
		width: 120px;
		// background-color: #eee;

	}


}

.page-title-container{
	// @media (min-width: 500px) {
	// 	margin-left: 40px;
	// }
	// @media (min-width: 700px) {
	// 	margin-left: 80px;
	// }

	margin-top: 100px;
	margin-bottom: 230px;
	@media (min-width: 700px) {
		margin-top: 130px;
		margin-bottom: 210px;
	}
	@media (min-width: 900px) {
		margin-top: 100px;
		margin-bottom: 100px;
	}
}

.page-title,
.sub-title{
	//margin-bottom: 20px;
	// @include panel();
	//padding: 10px 20px;
	text-align: center;
}

.page-title{
	margin: 0 auto;
	margin-bottom: 10px;	
	line-height: 1.3;
	max-width: 80%;
	@media (min-width: 700px) {
		max-width: 90%;
	}
	
	span{
		font-size: 30px;
		//letter-spacing: -1px;
		
		
		font-weight: 200;
		// border-bottom: 2px solid black;
		@media (min-width: 500px) {
			font-size: 40px;
			// border-bottom: 3px solid black;
		}
		@media (min-width: 700px) {
			font-size: 60px;
			// border-bottom: 3px solid black;
		}
		@media (min-width: 900px) {
			//border-bottom: 4px solid black;
		    // font-size: 80px;
		}


	}
	// font-weight: 500;
	.page-title-insert{
		border-bottom: 1px solid #000;
		@media (min-width: 500px) {
			border-bottom: 1px solid #000;
		}
		@media (min-width: 700px) {
			border-bottom: 3px solid #000;
		}
	}
	
}
.sub-title{
	
	@include font-medium();
}



.intro{
	padding-top: 40px;
	padding-bottom: 40px;
	// // @include panel();
	// font-size: 22px;
	// background: url(../assets/images/bg-2.jpg);

	@media (min-width: 450px) {
		display: flex;
	    width: 100%;
	    box-sizing: border-box;
	    padding-top: 80px;
	}
	@media (min-width: 700px) {
		padding-bottom: 80px;
		border-bottom: 1px solid #ccc;
		
		//position: absolute;

	}

	.image{
		padding: 10px;
		flex: 0.5;
		width: 50%;
   		 margin: 0 auto;
		@media (min-width: 700px) {
			flex:1;
			padding: 20px;
			    transform: rotate(-3deg);
		}
		img{
			// box-shadow: 4px 4px 22px -3px #999;
			box-shadow: 4px 3px 22px -1px #999;
		}
		
	}
	.text{

		
		flex:1;
		padding: 20px 0;
		margin-top: 20px;
		@media (min-width: 450px) {
			margin-left: 40px;
		}
		@media (min-width: 700px) {
			margin-top: 0px;
			flex:3;	
			padding: 0;
			margin-left: 60px;
		}
		@include font-medium();
		display: flex;
		flex-direction: column;
		justify-content: center;
		
		.release{
			@include font-small();
		}

		.summary{
			margin-bottom: 30px;
		}
		.bottom{
			// align-self: flex-end;
			// flex: 0;
   			//width: 100%;
		}
		.cta span{
			display: block;
			margin-bottom: 30px;
		}
		input.cta{
			// background-color: #68ae54;//
			// color: $color-green;
			border-radius: 0;
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			display: block;
			border: none;
			outline: none;
			color: white;
			padding: 10px 20px;
			display: inline;
			margin-top: 10px;
			text-decoration: none;
			font-size: 20px;
			// border: none;
			//background-color: $color-green;
			letter-spacing: 1px;
			border-bottom: 2px solid $color-green;
			font-weight: normal;
			color: $color-green;
			/* background: green; */
			padding: 7px 0;
			/* color: white; */
			&:hover{
				background: $color-green !important;
				color: #fff;
				padding: 7px 0;
			}
		}
	}

}


/*********
## Core ##
*********/

$color-green: #099f17;

html, 
body {
	font-size: 100%;
	margin: 0;
	@include font-small();
	color: $body-color;
	font-weight: 200;
	line-height: 1.5;
}

body {
	font-family: $body-font-family, "sans-serif";
}

.visually-hidden{
	@include visually-hidden();
}

h2{
	// font-weight: 400;
	@include font-small();
	margin-bottom: 40px;
	span{
		//padding: 10px 20px;
		// background-color: $color-green;
		// color: white;
		display: inline-block;
		// border-bottom: 1px solid black;
		font-weight: 500;
		text-decoration: underline;
		// letter-spacing: 3px;
		// text-transform: uppercase;
		@include visually-hidden();
	}
}

.content{
	max-width: 700px;
	margin: 0 auto;
	padding: 0 20px;

}

a{
	color: $color-green;
}

img{
	width: 100%;
	display: block;
}

.section{
	margin-bottom: 20px;
	@media (min-width: 700px) {
		margin-bottom: 60px;
	}
}


.micro-image{
	
    @media (min-width: 700px) {
    	display: flex;
    	flex-direction: row;
    	
    }
    @media (min-width: 1050px) {
    	width: 140%;
	    margin-left: -20%;
	    margin-right: -20%;
    }
    .image-wrapper{
    	flex: 1.5;
    	@media (min-width: 700px) {
    		flex: 1;
    	}
    	
    }
    .caption-wrapper{

    	flex: 1;
    	position: relative;
    	order: -1;
    	.caption{
    		
    		@include font-small();
			color: #000;
			font-style: italic;
			margin-top: 10px;
    		@media (min-width: 700px) {
    			position: absolute;
    			left: 0;
		    	bottom: 0;
				width: 90%;
				margin-top: 0;
    		}
    		@media (min-width: 900px) {
    			

    		}

    		@media (min-width: 1050px) {
    			left: 0;
		    	bottom: 11%;
		    	width: 60%;
		    	padding: 20px;
    			text-align: right;

    		}
		    
		    
		    
		    
		    
		    
		    /* background: green; */
		    
    	}
    }
    &.flip{
    	flex-direction: row-reverse;
    	.caption{
    		text-align: left;
    		// r: 0;
   			right: 0;
   			left: auto;
    	}
    }
}

.micro-text{
	
	line-height: 1.6;
	
	&.small{
		@include font-small();
		// max-width: 500px;
	    margin-left: auto;
	    margin-right: auto;
	    // margin-top: 50px;
	    // margin-bottom: 50px;
	    @media (min-width: 700px) {
	    	margin-top: 30px;
	    	margin-bottom: 30px;
	    }
	    line-height: 1.7;
	    > p{
	    	// max-width: 300px;
	    	margin-bottom: 40px;
	    	&:last-child{
	    		margin-bottom: 0;
	    	}
	    }
	}

	&.big{
		@include font-big();
	}

	&.center{

	}

	margin: 0 auto;
	padding: 20px 0 60px;
	@media (min-width: 700px) {
		padding: 60px 0;	
	}
	
}


.authors{
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 120px;

}
.author{
	margin-bottom: 40px;
	width: 200px;
	text-align: center;
	@media (min-width: 700px) {
		width: 300px;
	}
	
    
    &:nth-child(odd){
		@media (min-width: 440px) {
			margin-right: 30px;
		}
		@media (min-width: 700px) {
			margin-right: 90px;
		}
    		
    }
	>h3{
		margin-bottom: -10px;
		@include font-small();
		font-weight: 200;
		text-decoration: underline;
	}
	.profile-image{
	    width: 150px;
	    height: 150px;
	    display: inline-block;
	    border-radius: 50%;
	    overflow: hidden;
	    margin: 0 auto;
	    > img{
    	    width: 100%;
	    }
	}
}

footer{
    text-align: center;
    border-top: 1px solid lightgrey;
    padding-top: 40px;
}


// @font-face {
// 	font-family: 'A';
// 	src: url('../assets/fonts/A-M.woff') format('woff');
// 	font-weight: 500;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'A';
// 	src: url('../assets/A-B.eot');
// 	src: url('../assets/A-B.eot?#iefix') format('embedded-opentype'),
// 		url('../assets/A-B.woff') format('woff'),
// 		url('../assets/A-B.ttf') format('truetype');
// 	font-weight: B;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'A';
// 	src: url('../assets/fonts/A.woff') format('woff');
// 	font-weight: normal;
// 	font-style: normal;
// }

@font-face {
	font-family: 'A';
	src: url('../assets/fonts/A-L.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

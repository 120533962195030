/*******************************************
 * Fonts
 */
@font-face {
  font-family: 'A';
  src: url("../assets/fonts/A-L.woff") format("woff");
  font-weight: 300;
  font-style: normal; }

/* ==========================================================================
    GRIDLEX
    Just a Flexbox Grid System - v. 2.4.0
========================================================================== */
[class~="grid"],
[class*="grid-"],
[class*="grid_"] {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin: 0 -0.5rem; }

[class~="col"],
[class*="col-"],
[class*="col_"] {
  box-sizing: border-box;
  padding: 0 0.5rem 1rem;
  max-width: 100%; }

[class~="col"],
[class*="col_"] {
  flex: 1 1 0%; }

[class*="col-"] {
  flex: none; }

[class~="grid"][class~="col"],
[class~="grid"][class*="col-"],
[class~="grid"][class*="col_"],
[class*="grid-"][class~="col"],
[class*="grid-"][class*="col-"],
[class*="grid-"][class*="col_"],
[class*="grid_"][class~="col"],
[class*="grid_"][class*="col-"],
[class*="grid_"][class*="col_"] {
  margin: 0;
  padding: 0; }

/************************
    HELPERS SUFFIXES
*************************/
[class*="grid-"][class*="-noGutter"] {
  margin: 0; }
  [class*="grid-"][class*="-noGutter"] > [class~="col"],
  [class*="grid-"][class*="-noGutter"] > [class*="col-"] {
    padding: 0; }

[class*="grid-"][class*="-noWrap"] {
  flex-wrap: nowrap; }

[class*="grid-"][class*="-center"] {
  justify-content: center; }

[class*="grid-"][class*="-right"] {
  justify-content: flex-end;
  align-self: flex-end;
  margin-left: auto; }

[class*="grid-"][class*="-top"] {
  align-items: flex-start; }

[class*="grid-"][class*="-middle"] {
  align-items: center; }

[class*="grid-"][class*="-bottom"] {
  align-items: flex-end; }

[class*="grid-"][class*="-reverse"] {
  flex-direction: row-reverse; }

[class*="grid-"][class*="-column"] {
  flex-direction: column; }
  [class*="grid-"][class*="-column"] > [class*="col-"] {
    flex-basis: auto; }

[class*="grid-"][class*="-column-reverse"] {
  flex-direction: column-reverse; }

[class*="grid-"][class*="-spaceBetween"] {
  justify-content: space-between; }

[class*="grid-"][class*="-spaceAround"] {
  justify-content: space-around; }

[class*="grid-"][class*="-equalHeight"] > [class~="col"],
[class*="grid-"][class*="-equalHeight"] > [class*="col-"],
[class*="grid-"][class*="-equalHeight"] > [class*="col_"] {
  display: flex;
  flex-wrap: wrap; }
  [class*="grid-"][class*="-equalHeight"] > [class~="col"] > *,
  [class*="grid-"][class*="-equalHeight"] > [class*="col-"] > *,
  [class*="grid-"][class*="-equalHeight"] > [class*="col_"] > * {
    flex: 1 0 100%; }

[class*="grid-"][class*="-noBottom"] > [class~="col"],
[class*="grid-"][class*="-noBottom"] > [class*="col-"],
[class*="grid-"][class*="-noBottom"] > [class*="col_"] {
  padding-bottom: 0; }

[class*="col-"][class*="-top"] {
  align-self: flex-start; }

[class*="col-"][class*="-middle"] {
  align-self: center; }

[class*="col-"][class*="-bottom"] {
  align-self: flex-end; }

[class*="col-"][class*="-first"] {
  order: -1; }

[class*="col-"][class*="-last"] {
  order: 1; }

/************************
    GRID BY NUMBER
*************************/
[class*="grid-1"] > [class~="col"],
[class*="grid-1"] > [class*="col-"] {
  flex-basis: 100%;
  max-width: 100%; }

[class*="grid-2"] > [class~="col"],
[class*="grid-2"] > [class*="col-"] {
  flex-basis: 50%;
  max-width: 50%; }

[class*="grid-3"] > [class~="col"],
[class*="grid-3"] > [class*="col-"] {
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

[class*="grid-4"] > [class~="col"],
[class*="grid-4"] > [class*="col-"] {
  flex-basis: 25%;
  max-width: 25%; }

[class*="grid-5"] > [class~="col"],
[class*="grid-5"] > [class*="col-"] {
  flex-basis: 20%;
  max-width: 20%; }

[class*="grid-6"] > [class~="col"],
[class*="grid-6"] > [class*="col-"] {
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

[class*="grid-7"] > [class~="col"],
[class*="grid-7"] > [class*="col-"] {
  flex-basis: 14.28571%;
  max-width: 14.28571%; }

[class*="grid-8"] > [class~="col"],
[class*="grid-8"] > [class*="col-"] {
  flex-basis: 12.5%;
  max-width: 12.5%; }

[class*="grid-9"] > [class~="col"],
[class*="grid-9"] > [class*="col-"] {
  flex-basis: 11.11111%;
  max-width: 11.11111%; }

[class*="grid-10"] > [class~="col"],
[class*="grid-10"] > [class*="col-"] {
  flex-basis: 10%;
  max-width: 10%; }

[class*="grid-11"] > [class~="col"],
[class*="grid-11"] > [class*="col-"] {
  flex-basis: 9.09091%;
  max-width: 9.09091%; }

[class*="grid-12"] > [class~="col"],
[class*="grid-12"] > [class*="col-"] {
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

@media screen and (max-width: 80em) {
  [class*="_lg-1"] > [class~="col"],
  [class*="_lg-1"] > [class*="col-"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_lg-2"] > [class~="col"],
  [class*="_lg-2"] > [class*="col-"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_lg-3"] > [class~="col"],
  [class*="_lg-3"] > [class*="col-"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_lg-4"] > [class~="col"],
  [class*="_lg-4"] > [class*="col-"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_lg-5"] > [class~="col"],
  [class*="_lg-5"] > [class*="col-"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_lg-6"] > [class~="col"],
  [class*="_lg-6"] > [class*="col-"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_lg-7"] > [class~="col"],
  [class*="_lg-7"] > [class*="col-"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_lg-8"] > [class~="col"],
  [class*="_lg-8"] > [class*="col-"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_lg-9"] > [class~="col"],
  [class*="_lg-9"] > [class*="col-"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_lg-10"] > [class~="col"],
  [class*="_lg-10"] > [class*="col-"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_lg-11"] > [class~="col"],
  [class*="_lg-11"] > [class*="col-"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_lg-12"] > [class~="col"],
  [class*="_lg-12"] > [class*="col-"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media screen and (max-width: 64em) {
  [class*="_md-1"] > [class~="col"],
  [class*="_md-1"] > [class*="col-"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_md-2"] > [class~="col"],
  [class*="_md-2"] > [class*="col-"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_md-3"] > [class~="col"],
  [class*="_md-3"] > [class*="col-"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_md-4"] > [class~="col"],
  [class*="_md-4"] > [class*="col-"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_md-5"] > [class~="col"],
  [class*="_md-5"] > [class*="col-"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_md-6"] > [class~="col"],
  [class*="_md-6"] > [class*="col-"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_md-7"] > [class~="col"],
  [class*="_md-7"] > [class*="col-"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_md-8"] > [class~="col"],
  [class*="_md-8"] > [class*="col-"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_md-9"] > [class~="col"],
  [class*="_md-9"] > [class*="col-"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_md-10"] > [class~="col"],
  [class*="_md-10"] > [class*="col-"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_md-11"] > [class~="col"],
  [class*="_md-11"] > [class*="col-"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_md-12"] > [class~="col"],
  [class*="_md-12"] > [class*="col-"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media screen and (max-width: 48em) {
  [class*="_sm-1"] > [class~="col"],
  [class*="_sm-1"] > [class*="col-"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_sm-2"] > [class~="col"],
  [class*="_sm-2"] > [class*="col-"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_sm-3"] > [class~="col"],
  [class*="_sm-3"] > [class*="col-"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_sm-4"] > [class~="col"],
  [class*="_sm-4"] > [class*="col-"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_sm-5"] > [class~="col"],
  [class*="_sm-5"] > [class*="col-"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_sm-6"] > [class~="col"],
  [class*="_sm-6"] > [class*="col-"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_sm-7"] > [class~="col"],
  [class*="_sm-7"] > [class*="col-"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_sm-8"] > [class~="col"],
  [class*="_sm-8"] > [class*="col-"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_sm-9"] > [class~="col"],
  [class*="_sm-9"] > [class*="col-"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_sm-10"] > [class~="col"],
  [class*="_sm-10"] > [class*="col-"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_sm-11"] > [class~="col"],
  [class*="_sm-11"] > [class*="col-"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_sm-12"] > [class~="col"],
  [class*="_sm-12"] > [class*="col-"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

@media screen and (max-width: 35.5em) {
  [class*="_xs-1"] > [class~="col"],
  [class*="_xs-1"] > [class*="col-"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="_xs-2"] > [class~="col"],
  [class*="_xs-2"] > [class*="col-"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*="_xs-3"] > [class~="col"],
  [class*="_xs-3"] > [class*="col-"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*="_xs-4"] > [class~="col"],
  [class*="_xs-4"] > [class*="col-"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*="_xs-5"] > [class~="col"],
  [class*="_xs-5"] > [class*="col-"] {
    flex-basis: 20%;
    max-width: 20%; }
  [class*="_xs-6"] > [class~="col"],
  [class*="_xs-6"] > [class*="col-"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*="_xs-7"] > [class~="col"],
  [class*="_xs-7"] > [class*="col-"] {
    flex-basis: 14.28571%;
    max-width: 14.28571%; }
  [class*="_xs-8"] > [class~="col"],
  [class*="_xs-8"] > [class*="col-"] {
    flex-basis: 12.5%;
    max-width: 12.5%; }
  [class*="_xs-9"] > [class~="col"],
  [class*="_xs-9"] > [class*="col-"] {
    flex-basis: 11.11111%;
    max-width: 11.11111%; }
  [class*="_xs-10"] > [class~="col"],
  [class*="_xs-10"] > [class*="col-"] {
    flex-basis: 10%;
    max-width: 10%; }
  [class*="_xs-11"] > [class~="col"],
  [class*="_xs-11"] > [class*="col-"] {
    flex-basis: 9.09091%;
    max-width: 9.09091%; }
  [class*="_xs-12"] > [class~="col"],
  [class*="_xs-12"] > [class*="col-"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; } }

/************************
    COLS SIZES
*************************/
[class*='grid'] > [class*="col-1"] {
  flex-basis: 8.33333%;
  max-width: 8.33333%; }

[class*='grid'] > [class*="col-2"] {
  flex-basis: 16.66667%;
  max-width: 16.66667%; }

[class*='grid'] > [class*="col-3"] {
  flex-basis: 25%;
  max-width: 25%; }

[class*='grid'] > [class*="col-4"] {
  flex-basis: 33.33333%;
  max-width: 33.33333%; }

[class*='grid'] > [class*="col-5"] {
  flex-basis: 41.66667%;
  max-width: 41.66667%; }

[class*='grid'] > [class*="col-6"] {
  flex-basis: 50%;
  max-width: 50%; }

[class*='grid'] > [class*="col-7"] {
  flex-basis: 58.33333%;
  max-width: 58.33333%; }

[class*='grid'] > [class*="col-8"] {
  flex-basis: 66.66667%;
  max-width: 66.66667%; }

[class*='grid'] > [class*="col-9"] {
  flex-basis: 75%;
  max-width: 75%; }

[class*='grid'] > [class*="col-10"] {
  flex-basis: 83.33333%;
  max-width: 83.33333%; }

[class*='grid'] > [class*="col-11"] {
  flex-basis: 91.66667%;
  max-width: 91.66667%; }

[class*='grid'] > [class*="col-12"] {
  flex-basis: 100%;
  max-width: 100%; }

[class*="grid"] > [data-push-left*="off-0"] {
  margin-left: 0; }

[class*="grid"] > [data-push-left*="off-1"] {
  margin-left: 8.33333%; }

[class*="grid"] > [data-push-left*="off-2"] {
  margin-left: 16.66667%; }

[class*="grid"] > [data-push-left*="off-3"] {
  margin-left: 25%; }

[class*="grid"] > [data-push-left*="off-4"] {
  margin-left: 33.33333%; }

[class*="grid"] > [data-push-left*="off-5"] {
  margin-left: 41.66667%; }

[class*="grid"] > [data-push-left*="off-6"] {
  margin-left: 50%; }

[class*="grid"] > [data-push-left*="off-7"] {
  margin-left: 58.33333%; }

[class*="grid"] > [data-push-left*="off-8"] {
  margin-left: 66.66667%; }

[class*="grid"] > [data-push-left*="off-9"] {
  margin-left: 75%; }

[class*="grid"] > [data-push-left*="off-10"] {
  margin-left: 83.33333%; }

[class*="grid"] > [data-push-left*="off-11"] {
  margin-left: 91.66667%; }

[class*="grid"] > [data-push-right*="off-0"] {
  margin-right: 0; }

[class*="grid"] > [data-push-right*="off-1"] {
  margin-right: 8.33333%; }

[class*="grid"] > [data-push-right*="off-2"] {
  margin-right: 16.66667%; }

[class*="grid"] > [data-push-right*="off-3"] {
  margin-right: 25%; }

[class*="grid"] > [data-push-right*="off-4"] {
  margin-right: 33.33333%; }

[class*="grid"] > [data-push-right*="off-5"] {
  margin-right: 41.66667%; }

[class*="grid"] > [data-push-right*="off-6"] {
  margin-right: 50%; }

[class*="grid"] > [data-push-right*="off-7"] {
  margin-right: 58.33333%; }

[class*="grid"] > [data-push-right*="off-8"] {
  margin-right: 66.66667%; }

[class*="grid"] > [data-push-right*="off-9"] {
  margin-right: 75%; }

[class*="grid"] > [data-push-right*="off-10"] {
  margin-right: 83.33333%; }

[class*="grid"] > [data-push-right*="off-11"] {
  margin-right: 91.66667%; }

@media screen and (max-width: 80em) {
  [class*='grid'] > [class*="_lg-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class*='grid'] > [class*="_lg-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*='grid'] > [class*="_lg-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*='grid'] > [class*="_lg-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*='grid'] > [class*="_lg-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class*='grid'] > [class*="_lg-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*='grid'] > [class*="_lg-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class*='grid'] > [class*="_lg-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class*='grid'] > [class*="_lg-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class*='grid'] > [class*="_lg-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class*='grid'] > [class*="_lg-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class*='grid'] > [class*="_lg-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="grid"] > [data-push-left*="_lg-0"] {
    margin-left: 0; }
  [class*="grid"] > [data-push-left*="_lg-1"] {
    margin-left: 8.33333%; }
  [class*="grid"] > [data-push-left*="_lg-2"] {
    margin-left: 16.66667%; }
  [class*="grid"] > [data-push-left*="_lg-3"] {
    margin-left: 25%; }
  [class*="grid"] > [data-push-left*="_lg-4"] {
    margin-left: 33.33333%; }
  [class*="grid"] > [data-push-left*="_lg-5"] {
    margin-left: 41.66667%; }
  [class*="grid"] > [data-push-left*="_lg-6"] {
    margin-left: 50%; }
  [class*="grid"] > [data-push-left*="_lg-7"] {
    margin-left: 58.33333%; }
  [class*="grid"] > [data-push-left*="_lg-8"] {
    margin-left: 66.66667%; }
  [class*="grid"] > [data-push-left*="_lg-9"] {
    margin-left: 75%; }
  [class*="grid"] > [data-push-left*="_lg-10"] {
    margin-left: 83.33333%; }
  [class*="grid"] > [data-push-left*="_lg-11"] {
    margin-left: 91.66667%; }
  [class*="grid"] > [data-push-right*="_lg-0"] {
    margin-right: 0; }
  [class*="grid"] > [data-push-right*="_lg-1"] {
    margin-right: 8.33333%; }
  [class*="grid"] > [data-push-right*="_lg-2"] {
    margin-right: 16.66667%; }
  [class*="grid"] > [data-push-right*="_lg-3"] {
    margin-right: 25%; }
  [class*="grid"] > [data-push-right*="_lg-4"] {
    margin-right: 33.33333%; }
  [class*="grid"] > [data-push-right*="_lg-5"] {
    margin-right: 41.66667%; }
  [class*="grid"] > [data-push-right*="_lg-6"] {
    margin-right: 50%; }
  [class*="grid"] > [data-push-right*="_lg-7"] {
    margin-right: 58.33333%; }
  [class*="grid"] > [data-push-right*="_lg-8"] {
    margin-right: 66.66667%; }
  [class*="grid"] > [data-push-right*="_lg-9"] {
    margin-right: 75%; }
  [class*="grid"] > [data-push-right*="_lg-10"] {
    margin-right: 83.33333%; }
  [class*="grid"] > [data-push-right*="_lg-11"] {
    margin-right: 91.66667%; }
  [class*='grid'] [class*="_lg-first"] {
    order: -1; }
  [class*='grid'] [class*="_lg-last"] {
    order: 1; } }

@media screen and (max-width: 64em) {
  [class*='grid'] > [class*="_md-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class*='grid'] > [class*="_md-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*='grid'] > [class*="_md-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*='grid'] > [class*="_md-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*='grid'] > [class*="_md-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class*='grid'] > [class*="_md-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*='grid'] > [class*="_md-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class*='grid'] > [class*="_md-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class*='grid'] > [class*="_md-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class*='grid'] > [class*="_md-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class*='grid'] > [class*="_md-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class*='grid'] > [class*="_md-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="grid"] > [data-push-left*="_md-0"] {
    margin-left: 0; }
  [class*="grid"] > [data-push-left*="_md-1"] {
    margin-left: 8.33333%; }
  [class*="grid"] > [data-push-left*="_md-2"] {
    margin-left: 16.66667%; }
  [class*="grid"] > [data-push-left*="_md-3"] {
    margin-left: 25%; }
  [class*="grid"] > [data-push-left*="_md-4"] {
    margin-left: 33.33333%; }
  [class*="grid"] > [data-push-left*="_md-5"] {
    margin-left: 41.66667%; }
  [class*="grid"] > [data-push-left*="_md-6"] {
    margin-left: 50%; }
  [class*="grid"] > [data-push-left*="_md-7"] {
    margin-left: 58.33333%; }
  [class*="grid"] > [data-push-left*="_md-8"] {
    margin-left: 66.66667%; }
  [class*="grid"] > [data-push-left*="_md-9"] {
    margin-left: 75%; }
  [class*="grid"] > [data-push-left*="_md-10"] {
    margin-left: 83.33333%; }
  [class*="grid"] > [data-push-left*="_md-11"] {
    margin-left: 91.66667%; }
  [class*="grid"] > [data-push-right*="_md-0"] {
    margin-right: 0; }
  [class*="grid"] > [data-push-right*="_md-1"] {
    margin-right: 8.33333%; }
  [class*="grid"] > [data-push-right*="_md-2"] {
    margin-right: 16.66667%; }
  [class*="grid"] > [data-push-right*="_md-3"] {
    margin-right: 25%; }
  [class*="grid"] > [data-push-right*="_md-4"] {
    margin-right: 33.33333%; }
  [class*="grid"] > [data-push-right*="_md-5"] {
    margin-right: 41.66667%; }
  [class*="grid"] > [data-push-right*="_md-6"] {
    margin-right: 50%; }
  [class*="grid"] > [data-push-right*="_md-7"] {
    margin-right: 58.33333%; }
  [class*="grid"] > [data-push-right*="_md-8"] {
    margin-right: 66.66667%; }
  [class*="grid"] > [data-push-right*="_md-9"] {
    margin-right: 75%; }
  [class*="grid"] > [data-push-right*="_md-10"] {
    margin-right: 83.33333%; }
  [class*="grid"] > [data-push-right*="_md-11"] {
    margin-right: 91.66667%; }
  [class*='grid'] [class*="_md-first"] {
    order: -1; }
  [class*='grid'] [class*="_md-last"] {
    order: 1; } }

@media screen and (max-width: 48em) {
  [class*='grid'] > [class*="_sm-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class*='grid'] > [class*="_sm-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*='grid'] > [class*="_sm-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*='grid'] > [class*="_sm-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*='grid'] > [class*="_sm-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class*='grid'] > [class*="_sm-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*='grid'] > [class*="_sm-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class*='grid'] > [class*="_sm-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class*='grid'] > [class*="_sm-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class*='grid'] > [class*="_sm-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class*='grid'] > [class*="_sm-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class*='grid'] > [class*="_sm-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="grid"] > [data-push-left*="_sm-0"] {
    margin-left: 0; }
  [class*="grid"] > [data-push-left*="_sm-1"] {
    margin-left: 8.33333%; }
  [class*="grid"] > [data-push-left*="_sm-2"] {
    margin-left: 16.66667%; }
  [class*="grid"] > [data-push-left*="_sm-3"] {
    margin-left: 25%; }
  [class*="grid"] > [data-push-left*="_sm-4"] {
    margin-left: 33.33333%; }
  [class*="grid"] > [data-push-left*="_sm-5"] {
    margin-left: 41.66667%; }
  [class*="grid"] > [data-push-left*="_sm-6"] {
    margin-left: 50%; }
  [class*="grid"] > [data-push-left*="_sm-7"] {
    margin-left: 58.33333%; }
  [class*="grid"] > [data-push-left*="_sm-8"] {
    margin-left: 66.66667%; }
  [class*="grid"] > [data-push-left*="_sm-9"] {
    margin-left: 75%; }
  [class*="grid"] > [data-push-left*="_sm-10"] {
    margin-left: 83.33333%; }
  [class*="grid"] > [data-push-left*="_sm-11"] {
    margin-left: 91.66667%; }
  [class*="grid"] > [data-push-right*="_sm-0"] {
    margin-right: 0; }
  [class*="grid"] > [data-push-right*="_sm-1"] {
    margin-right: 8.33333%; }
  [class*="grid"] > [data-push-right*="_sm-2"] {
    margin-right: 16.66667%; }
  [class*="grid"] > [data-push-right*="_sm-3"] {
    margin-right: 25%; }
  [class*="grid"] > [data-push-right*="_sm-4"] {
    margin-right: 33.33333%; }
  [class*="grid"] > [data-push-right*="_sm-5"] {
    margin-right: 41.66667%; }
  [class*="grid"] > [data-push-right*="_sm-6"] {
    margin-right: 50%; }
  [class*="grid"] > [data-push-right*="_sm-7"] {
    margin-right: 58.33333%; }
  [class*="grid"] > [data-push-right*="_sm-8"] {
    margin-right: 66.66667%; }
  [class*="grid"] > [data-push-right*="_sm-9"] {
    margin-right: 75%; }
  [class*="grid"] > [data-push-right*="_sm-10"] {
    margin-right: 83.33333%; }
  [class*="grid"] > [data-push-right*="_sm-11"] {
    margin-right: 91.66667%; }
  [class*='grid'] [class*="_sm-first"] {
    order: -1; }
  [class*='grid'] [class*="_sm-last"] {
    order: 1; } }

@media screen and (max-width: 35.5em) {
  [class*='grid'] > [class*="_xs-1"] {
    flex-basis: 8.33333%;
    max-width: 8.33333%; }
  [class*='grid'] > [class*="_xs-2"] {
    flex-basis: 16.66667%;
    max-width: 16.66667%; }
  [class*='grid'] > [class*="_xs-3"] {
    flex-basis: 25%;
    max-width: 25%; }
  [class*='grid'] > [class*="_xs-4"] {
    flex-basis: 33.33333%;
    max-width: 33.33333%; }
  [class*='grid'] > [class*="_xs-5"] {
    flex-basis: 41.66667%;
    max-width: 41.66667%; }
  [class*='grid'] > [class*="_xs-6"] {
    flex-basis: 50%;
    max-width: 50%; }
  [class*='grid'] > [class*="_xs-7"] {
    flex-basis: 58.33333%;
    max-width: 58.33333%; }
  [class*='grid'] > [class*="_xs-8"] {
    flex-basis: 66.66667%;
    max-width: 66.66667%; }
  [class*='grid'] > [class*="_xs-9"] {
    flex-basis: 75%;
    max-width: 75%; }
  [class*='grid'] > [class*="_xs-10"] {
    flex-basis: 83.33333%;
    max-width: 83.33333%; }
  [class*='grid'] > [class*="_xs-11"] {
    flex-basis: 91.66667%;
    max-width: 91.66667%; }
  [class*='grid'] > [class*="_xs-12"] {
    flex-basis: 100%;
    max-width: 100%; }
  [class*="grid"] > [data-push-left*="_xs-0"] {
    margin-left: 0; }
  [class*="grid"] > [data-push-left*="_xs-1"] {
    margin-left: 8.33333%; }
  [class*="grid"] > [data-push-left*="_xs-2"] {
    margin-left: 16.66667%; }
  [class*="grid"] > [data-push-left*="_xs-3"] {
    margin-left: 25%; }
  [class*="grid"] > [data-push-left*="_xs-4"] {
    margin-left: 33.33333%; }
  [class*="grid"] > [data-push-left*="_xs-5"] {
    margin-left: 41.66667%; }
  [class*="grid"] > [data-push-left*="_xs-6"] {
    margin-left: 50%; }
  [class*="grid"] > [data-push-left*="_xs-7"] {
    margin-left: 58.33333%; }
  [class*="grid"] > [data-push-left*="_xs-8"] {
    margin-left: 66.66667%; }
  [class*="grid"] > [data-push-left*="_xs-9"] {
    margin-left: 75%; }
  [class*="grid"] > [data-push-left*="_xs-10"] {
    margin-left: 83.33333%; }
  [class*="grid"] > [data-push-left*="_xs-11"] {
    margin-left: 91.66667%; }
  [class*="grid"] > [data-push-right*="_xs-0"] {
    margin-right: 0; }
  [class*="grid"] > [data-push-right*="_xs-1"] {
    margin-right: 8.33333%; }
  [class*="grid"] > [data-push-right*="_xs-2"] {
    margin-right: 16.66667%; }
  [class*="grid"] > [data-push-right*="_xs-3"] {
    margin-right: 25%; }
  [class*="grid"] > [data-push-right*="_xs-4"] {
    margin-right: 33.33333%; }
  [class*="grid"] > [data-push-right*="_xs-5"] {
    margin-right: 41.66667%; }
  [class*="grid"] > [data-push-right*="_xs-6"] {
    margin-right: 50%; }
  [class*="grid"] > [data-push-right*="_xs-7"] {
    margin-right: 58.33333%; }
  [class*="grid"] > [data-push-right*="_xs-8"] {
    margin-right: 66.66667%; }
  [class*="grid"] > [data-push-right*="_xs-9"] {
    margin-right: 75%; }
  [class*="grid"] > [data-push-right*="_xs-10"] {
    margin-right: 83.33333%; }
  [class*="grid"] > [data-push-right*="_xs-11"] {
    margin-right: 91.66667%; }
  [class*='grid'] [class*="_xs-first"] {
    order: -1; }
  [class*='grid'] [class*="_xs-last"] {
    order: 1; } }

/************************
    HIDING COLS
*************************/
[class*="col-"]:not([class*="grid"]):not([class*="col-0"]) {
  display: block; }

[class~="grid"][class*="col-"]:not([class*="col-0"]) {
  display: flex; }

[class*="col-"][class*="col-0"] {
  display: none; }

@media screen and (max-width: 80em) {
  [class*="grid"] > :not([class*="_lg-0"]) {
    display: block; }
  [class*="grid"]:not([class*="_lg-0"]) {
    display: flex; }
  [class*="grid"] > [class*="_lg-0"],
  [class*="grid"][class*="-equalHeight"] > [class*="_lg-0"] {
    display: none; } }

@media screen and (max-width: 64em) {
  [class*="grid"] > :not([class*="_md-0"]) {
    display: block; }
  [class*="grid"]:not([class*="_md-0"]) {
    display: flex; }
  [class*="grid"] > [class*="_md-0"],
  [class*="grid"][class*="-equalHeight"] > [class*="_md-0"] {
    display: none; } }

@media screen and (max-width: 48em) {
  [class*="grid"] > :not([class*="_sm-0"]) {
    display: block; }
  [class*="grid"]:not([class*="_sm-0"]) {
    display: flex; }
  [class*="grid"] > [class*="_sm-0"],
  [class*="grid"][class*="-equalHeight"] > [class*="_sm-0"] {
    display: none; } }

@media screen and (max-width: 35.5em) {
  [class*="grid"] > :not([class*="_xs-0"]) {
    display: block; }
  [class*="grid"]:not([class*="_xs-0"]) {
    display: flex; }
  [class*="grid"] > [class*="_xs-0"],
  [class*="grid"][class*="-equalHeight"] > [class*="_xs-0"] {
    display: none; } }

/*******************************************
 * Variables
 */
/**********
## Fonts ##
**********/
/************
## Spacing ##
************/
/*******************************************
 * Functions
 */
/***************
## Strip Unit ##
***************/
/*******************
## CONVERT TO REM ##
*******************/
/*************
## REM CALC ##
*************/
/*******************************************
 * Mixins
 */
/************
## Helpers ##
************/
/*******************************************
 * Other site styles
 */
/*********
## Core ##
*********/
html,
body {
  font-size: 100%;
  margin: 0;
  font-size: 18px;
  color: black;
  font-weight: 200;
  line-height: 1.5; }
  @media (min-width: 700px) {
    html,
    body {
      font-size: 22px; } }

body {
  font-family: "A", "sans-serif"; }

.visually-hidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute; }

h2 {
  font-size: 18px;
  margin-bottom: 40px; }
  @media (min-width: 700px) {
    h2 {
      font-size: 22px; } }
  h2 span {
    display: inline-block;
    font-weight: 500;
    text-decoration: underline;
    margin: -1px;
    padding: 0;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    clip: rect(0, 0, 0, 0);
    position: absolute; }

.content {
  max-width: 700px;
  margin: 0 auto;
  padding: 0 20px; }

a {
  color: #099f17; }

img {
  width: 100%;
  display: block; }

.section {
  margin-bottom: 20px; }
  @media (min-width: 700px) {
    .section {
      margin-bottom: 60px; } }

@media (min-width: 700px) {
  .micro-image {
    display: flex;
    flex-direction: row; } }

@media (min-width: 1050px) {
  .micro-image {
    width: 140%;
    margin-left: -20%;
    margin-right: -20%; } }

.micro-image .image-wrapper {
  flex: 1.5; }
  @media (min-width: 700px) {
    .micro-image .image-wrapper {
      flex: 1; } }

.micro-image .caption-wrapper {
  flex: 1;
  position: relative;
  order: -1; }
  .micro-image .caption-wrapper .caption {
    font-size: 18px;
    color: #000;
    font-style: italic;
    margin-top: 10px;
    /* background: green; */ }
    @media (min-width: 700px) {
      .micro-image .caption-wrapper .caption {
        font-size: 22px; } }
    @media (min-width: 700px) {
      .micro-image .caption-wrapper .caption {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 90%;
        margin-top: 0; } }
    @media (min-width: 1050px) {
      .micro-image .caption-wrapper .caption {
        left: 0;
        bottom: 11%;
        width: 60%;
        padding: 20px;
        text-align: right; } }

.micro-image.flip {
  flex-direction: row-reverse; }
  .micro-image.flip .caption {
    text-align: left;
    right: 0;
    left: auto; }

.micro-text {
  line-height: 1.6;
  margin: 0 auto;
  padding: 20px 0 60px; }
  .micro-text.small {
    font-size: 18px;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.7; }
    @media (min-width: 700px) {
      .micro-text.small {
        font-size: 22px; } }
    @media (min-width: 700px) {
      .micro-text.small {
        margin-top: 30px;
        margin-bottom: 30px; } }
    .micro-text.small > p {
      margin-bottom: 40px; }
      .micro-text.small > p:last-child {
        margin-bottom: 0; }
  .micro-text.big {
    font-size: 24px; }
    @media (min-width: 700px) {
      .micro-text.big {
        font-size: 32px; } }
  @media (min-width: 700px) {
    .micro-text {
      padding: 60px 0; } }

.authors {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 120px; }

.author {
  margin-bottom: 40px;
  width: 200px;
  text-align: center; }
  @media (min-width: 700px) {
    .author {
      width: 300px; } }
  @media (min-width: 440px) {
    .author:nth-child(odd) {
      margin-right: 30px; } }
  @media (min-width: 700px) {
    .author:nth-child(odd) {
      margin-right: 90px; } }
  .author > h3 {
    margin-bottom: -10px;
    font-size: 18px;
    font-weight: 200;
    text-decoration: underline; }
    @media (min-width: 700px) {
      .author > h3 {
        font-size: 22px; } }
  .author .profile-image {
    width: 150px;
    height: 150px;
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto; }
    .author .profile-image > img {
      width: 100%; }

footer {
  text-align: center;
  border-top: 1px solid lightgrey;
  padding-top: 40px; }

/*******************************************
 * Atoms, molecules, organisms and templates
 */
/**
 * atom / example
 */
span.example {
  display: flex;
  background: linear-gradient(black, green);
  text-align: left;
  color: red; }

.header {
  background: url(../assets/images/mt-bg.jpg);
  background-size: cover;
  background-position: right 22% center;
  padding-bottom: 40px; }
  @media (min-width: 700px) {
    .header {
      background-position: center center; } }
  @media (min-width: 500px) {
    .header {
      padding-bottom: 60px; } }
  @media (min-width: 700px) {
    .header {
      padding-bottom: 80px; } }
  @media (min-width: 900px) {
    .header {
      padding: 0;
      height: 80vh; } }

.page-intro-container {
  text-align: center; }

.page-intro {
  margin-bottom: 10px;
  font-size: 14px;
  color: #000;
  display: inline-block;
  padding: 5px 0;
  margin-top: 20px;
  margin-bottom: 20px; }
  @media (min-width: 700px) {
    .page-intro {
      font-size: 16px; } }
  .page-intro .logo {
    width: 120px; }

.page-title-container {
  margin-top: 100px;
  margin-bottom: 230px; }
  @media (min-width: 700px) {
    .page-title-container {
      margin-top: 130px;
      margin-bottom: 210px; } }
  @media (min-width: 900px) {
    .page-title-container {
      margin-top: 100px;
      margin-bottom: 100px; } }

.page-title,
.sub-title {
  text-align: center; }

.page-title {
  margin: 0 auto;
  margin-bottom: 10px;
  line-height: 1.3;
  max-width: 80%; }
  @media (min-width: 700px) {
    .page-title {
      max-width: 90%; } }
  .page-title span {
    font-size: 30px;
    font-weight: 200; }
    @media (min-width: 500px) {
      .page-title span {
        font-size: 40px; } }
    @media (min-width: 700px) {
      .page-title span {
        font-size: 60px; } }
  .page-title .page-title-insert {
    border-bottom: 1px solid #000; }
    @media (min-width: 500px) {
      .page-title .page-title-insert {
        border-bottom: 1px solid #000; } }
    @media (min-width: 700px) {
      .page-title .page-title-insert {
        border-bottom: 3px solid #000; } }

.sub-title {
  font-size: 22px; }
  @media (min-width: 700px) {
    .sub-title {
      font-size: 26px; } }

.intro {
  padding-top: 40px;
  padding-bottom: 40px; }
  @media (min-width: 450px) {
    .intro {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      padding-top: 80px; } }
  @media (min-width: 700px) {
    .intro {
      padding-bottom: 80px;
      border-bottom: 1px solid #ccc; } }
  .intro .image {
    padding: 10px;
    flex: 0.5;
    width: 50%;
    margin: 0 auto; }
    @media (min-width: 700px) {
      .intro .image {
        flex: 1;
        padding: 20px;
        transform: rotate(-3deg); } }
    .intro .image img {
      box-shadow: 4px 3px 22px -1px #999; }
  .intro .text {
    flex: 1;
    padding: 20px 0;
    margin-top: 20px;
    font-size: 22px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    @media (min-width: 450px) {
      .intro .text {
        margin-left: 40px; } }
    @media (min-width: 700px) {
      .intro .text {
        margin-top: 0px;
        flex: 3;
        padding: 0;
        margin-left: 60px; } }
    @media (min-width: 700px) {
      .intro .text {
        font-size: 26px; } }
    .intro .text .release {
      font-size: 18px; }
      @media (min-width: 700px) {
        .intro .text .release {
          font-size: 22px; } }
    .intro .text .summary {
      margin-bottom: 30px; }
    .intro .text .cta span {
      display: block;
      margin-bottom: 30px; }
    .intro .text input.cta {
      border-radius: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      display: block;
      border: none;
      outline: none;
      color: white;
      padding: 10px 20px;
      display: inline;
      margin-top: 10px;
      text-decoration: none;
      font-size: 20px;
      letter-spacing: 1px;
      border-bottom: 2px solid #099f17;
      font-weight: normal;
      color: #099f17;
      /* background: green; */
      padding: 7px 0;
      /* color: white; */ }
      .intro .text input.cta:hover {
        background: #099f17 !important;
        color: #fff;
        padding: 7px 0; }

div.example {
  background: #FF0000;
  color: #000;
  padding: 20px; }
